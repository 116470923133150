/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import {
  sendRegisterRequestToFramework,
  sendRequestToFramework
} from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { INTERACTION_DIRECTION_TYPES } from '../models/Interactions';
import { CHANNEL_TYPES } from '../models/ChannelTypes';

/**
 * Use this to revive an event whenever screenpops are disabled or enabled.
 *
 * When disabled screen pops should not be preformed either when [[screenpop]] or [[setInteraction]] is called.
 *
 * @param callback Called when the status of the screenpop control changes.
 * The application should not preform screenpops when screenPopEnabled is false.
 */
export function registerScreenpopControlChanged(
  callback: (screenPopEnabled: boolean) => Promise<void>
) {
  return sendRegisterRequestToFramework(
    callback,
    OPERATIONS.SCREENPOP_CONTROL_CHANGED
  );
}

/**
 * Sets whether or not screenpop is enabled.
 *
 * See [[registerScreenpopControlChanged]] for more.
 * @param enabled
 */
export function setScreenpopControl(enabled: boolean): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SCREENPOP_CONTROL_CHANGED, [
    enabled
  ]).then((val) => {}); // make sure we return void
}

/**
 * Apps integrated with a CRM should register for this so that other apps can open record/entities in
 * CRM if they have the record's ID, say from [[registerOnFocus]] or the like.
 *
 * See [[screenpop]]
 *
 * @param callback Called when another App requests a screenpop of the given object
 */
export function registerScreenpop(
  callback: (
    channel: CHANNEL_TYPES,
    direction: INTERACTION_DIRECTION_TYPES,
    objectId: string,
    objectType: string
  ) => Promise<void>
) {
  return sendRegisterRequestToFramework(callback, OPERATIONS.SCREEN_POP);
}

/**
 * This causes a screenpop on the application of given record.
 * This is useful if you want to open a record you received from [[registerOnFocus]] or the like.
 *
 * See [[registerScreenpop]] for more.
 *
 * @param channel Type of channel that caused the screenpop
 * @param direction Direction of the interaction that caused the screenpop
 * @param objectId Id of the object to pop
 * @param objectType Type of the object to pop
 */
export function screenpop(
  channel: CHANNEL_TYPES,
  direction: INTERACTION_DIRECTION_TYPES,
  objectId: string,
  objectType: string
): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SCREEN_POP, [
    channel,
    direction,
    objectId,
    objectType
  ]).then((val) => {}); // make sure we return void
}
