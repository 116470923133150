/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { IField } from './Field';

/**
 * Represents a record or entity in CRM
 */
export class RecordItem implements IRecordItem {
  fields: { [key: string]: IField };
  id: string;
  type: string;
  displayName: string;

  static fromJSON(input: IRecordItem) {
    return new RecordItem(
      input.id,
      input.type,
      input.displayName,
      input.fields
    );
  }

  constructor(
    id: string,
    type: string,
    displayName: string,
    fields?: { [key: string]: IField }
  ) {
    this.fields = fields || {};
    this.id = id;
    this.displayName = displayName;
    this.type = type;
  }

  toJSON(): IRecordItem {
    return {
      id: this.id,
      displayName: this.displayName,
      type: this.type,
      fields: this.fields
    };
  }

  getMetadata() {
    return { Id: this.id, Type: this.type, DisplayName: this.displayName };
  }

  setEmail(fieldName: string, displayName: string, value: any) {
    this.fields['Email'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getEmail() {
    return this.fields['Email'];
  }

  setPhone(fieldName: string, displayName: string, value: any) {
    this.fields['Phone'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getPhone() {
    return this.fields['Phone'];
  }

  setHomePhone(fieldName: string, displayName: string, value: any) {
    this.fields['HomePhone'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getHomePhone() {
    return this.fields['HomePhone'];
  }

  setOtherPhone(fieldName: string, displayName: string, value: any) {
    this.fields['OtherPhone'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getOtherPhone() {
    return this.fields['OtherPhone'];
  }

  setMobile(fieldName: string, displayName: string, value: any) {
    this.fields['Mobile'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getMobile() {
    return this.fields['Mobile'];
  }

  setFax(fieldName: string, displayName: string, value: any) {
    this.fields['Fax'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getFax() {
    return this.fields['Fax'];
  }

  setFirstName(fieldName: string, displayName: string, value: any) {
    this.fields['FirstName'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getFirstName() {
    return this.fields['FirstName'];
  }

  setLastName(fieldName: string, displayName: string, value: any) {
    this.fields['LastName'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getLastName() {
    return this.fields['LastName'];
  }

  setFullName(fieldName: string, displayName: string, value: any) {
    this.fields['FullName'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getFullName() {
    return this.fields['FullName'];
  }

  setAccountName(fieldName: string, displayName: string, value: any) {
    this.fields['AccountName'] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getAccountName() {
    return this.fields['AccountName'];
  }

  setField(key: string, fieldName: string, displayName: string, value: any) {
    this.fields[key] = {
      DevName: fieldName,
      DisplayName: displayName,
      Value: value
    };
  }

  getField(key: string) {
    return this.fields[key];
  }
}

/**
 * @private
 * Underlies [[RecordItem]]
 */
export interface IRecordItem {
  /** Name of field -> field */
  fields: { [key: string]: IField };
  id: string;
  type: string;
  displayName: string;
}
