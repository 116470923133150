/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { NOTIFICATION_TYPE } from '../models/NotificationType';

/**
 * This sends notification to framework which will be displayed in toolbar.
 * To Do : Different notifications based on type.
 * @param message Message to be displayed on toolbar.
 * @param notificationType Type of notification.
 */
export function sendNotification(
  message: string,
  notificationType?: NOTIFICATION_TYPE
): Promise<void> {
  return sendRequestToFramework(OPERATIONS.NOTIFICATION_TO_FRAMEWORK, [
    message,
    notificationType
  ]).then((val) => {}); // make sure we return void
}
