/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import * as $ from 'jquery';
import { LOG_LEVEL } from './LogLevel';
import { LogObj } from './LogObj';
import { ERROR_CODE } from './ErrorCode';
import { LOG_SOURCE } from './LogSource';

export * from './LogLevel';
export * from './LogObj';
export * from './ErrorCode';
export * from './LogSource';

/**
 * Logger for AMC's DaVinci Apps.
 * Currently there is no way for 3rd party apps to make use of this.
 */
export class Logger {
  private static timerId: number = null;
  private static ApiServiceUrl: string = null;
  public static tabId = '00000000-0000-0000-0000-000000000000';
  private static loopBuffer: LogObj[] = [];
  private static traceBuffer: LogObj[] = [];
  private static debugBuffer: LogObj[] = [];
  private static informationBuffer: LogObj[] = [];
  private static warningBuffer: LogObj[] = [];
  private static errorBuffer: LogObj[] = [];
  private static criticalBuffer: LogObj[] = [];

  private _logSource: string;
  private _logLevel: LOG_LEVEL = LOG_LEVEL.Information;

  private _isDev = false;

  constructor(
    logSource: LOG_SOURCE | string,
    isDev: boolean = false,
    apiUrl: string = null
  ) {
    this._logSource = logSource;
    if (this._logLevel == null) {
      this._logLevel = LOG_LEVEL.Loop;
    }

    this.getTabIdFromSession();

    if (Logger.timerId == null) {
      Logger.timerId = window.setInterval(this.pushLogs.bind(this), 60000);
    }
    if (apiUrl != null && /\S/.test(apiUrl) && apiUrl !== undefined) {
      this.apiUrl = apiUrl;
    }

    this._isDev = isDev;
  }

  private getTabIdFromSession() {
    const sessionTabId = sessionStorage.getItem('LoggerTabId');
    if (sessionTabId != null) {
      Logger.tabId = sessionTabId;
    } else {
      Logger.tabId = this.uuidv4();
      sessionStorage.setItem('LoggerTabId', Logger.tabId);
    }
  }

  private uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0,
          // eslint-disable-next-line no-bitwise, eqeqeq
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  private static getLogBufferReference(logLevel: LOG_LEVEL): LogObj[] {
    switch (logLevel) {
      case LOG_LEVEL.Loop:
        return Logger.loopBuffer;
      case LOG_LEVEL.Trace:
        return Logger.traceBuffer;
      case LOG_LEVEL.Debug:
        return Logger.debugBuffer;
      case LOG_LEVEL.Information:
        return Logger.informationBuffer;
      case LOG_LEVEL.Warning:
        return Logger.warningBuffer;
      case LOG_LEVEL.Error:
        return Logger.errorBuffer;
      case LOG_LEVEL.Critical:
        return Logger.criticalBuffer;
      default:
        return [];
    }
  }

  static clearTimeOutForLogger(): void {
    clearTimeout(Logger.timerId);
    Logger.timerId = null;
  }

  get apiUrl() {
    return Logger.ApiServiceUrl;
  }

  set apiUrl(apiUrl: string) {
    Logger.ApiServiceUrl = apiUrl;
    this.syncLogLevel();
  }

  get isDev(): boolean {
    return this._isDev;
  }

  set isDev(isDev: boolean) {
    this._isDev = isDev;
  }

  get logLevel(): LOG_LEVEL {
    return this._logLevel;
  }

  set logLevel(logLevel: LOG_LEVEL) {
    this._logLevel = logLevel;
  }

  get logSource() {
    return this._logSource;
  }

  set logSource(logSourceIn: LOG_SOURCE | string) {
    this._logSource = logSourceIn;
  }

  private printConsoleLog(logObj: LogObj): void {
    if (this._isDev) {
      console.log(JSON.stringify(logObj, null, '\t'));
    }
  }

  private generateLogObj(
    logLevel: LOG_LEVEL,
    errorCode: ERROR_CODE,
    message: string,
    localTime: Date = new Date()
  ): LogObj {
    return new LogObj(
      errorCode,
      message,
      logLevel,
      this.logLevel,
      Logger.tabId,
      Logger.tabId,
      localTime
    );
  }

  private logMessage(
    methodLogLevel: LOG_LEVEL,
    message: string,
    errorCode: ERROR_CODE,
    localTime: Date
  ): void {
    const logObj: LogObj = this.generateLogObj(
      methodLogLevel,
      errorCode,
      message,
      localTime
    );

    if (this.logLevel <= methodLogLevel) {
      if (this._isDev) {
        this.printConsoleLog(logObj);
      } else {
        Logger.getLogBufferReference(methodLogLevel).push(logObj);
      }
    }
  }

  logLoop(
    message: string,
    errorCode: ERROR_CODE = ERROR_CODE.Other,
    localTime: Date = new Date()
  ): void {
    this.logMessage(LOG_LEVEL.Loop, message, errorCode, localTime);
  }

  logTrace(
    message: string,
    errorCode: ERROR_CODE = ERROR_CODE.Other,
    localTime: Date = new Date()
  ): void {
    this.logMessage(LOG_LEVEL.Trace, message, errorCode, localTime);
  }

  logDebug(
    message: string,
    errorCode: ERROR_CODE = ERROR_CODE.Other,
    localTime: Date = new Date()
  ): void {
    this.logMessage(LOG_LEVEL.Debug, message, errorCode, localTime);
  }

  logInformation(
    message: string,
    errorCode: ERROR_CODE = ERROR_CODE.Other,
    localTime: Date = new Date()
  ): void {
    this.logMessage(LOG_LEVEL.Information, message, errorCode, localTime);
  }

  logWarning(
    message: string,
    errorCode: ERROR_CODE = ERROR_CODE.Other,
    localTime: Date = new Date()
  ): void {
    this.logMessage(LOG_LEVEL.Warning, message, errorCode, localTime);
  }

  logError(
    message: string,
    errorCode: ERROR_CODE = ERROR_CODE.Other,
    localTime: Date = new Date()
  ): void {
    this.logMessage(LOG_LEVEL.Error, message, errorCode, localTime);
  }

  logCriticial(
    message: string,
    errorCode: ERROR_CODE = ERROR_CODE.Other,
    localTime: Date = new Date()
  ): void {
    this.logMessage(LOG_LEVEL.Critical, message, errorCode, localTime);
  }

  pushLogs(): void {
    if (!this.apiUrl) {
      return;
    }
    this.postBuffers(LOG_LEVEL.Loop);
    this.postBuffers(LOG_LEVEL.Trace);
    this.postBuffers(LOG_LEVEL.Debug);
    this.postBuffers(LOG_LEVEL.Information);
    this.postBuffers(LOG_LEVEL.Warning);
    this.postBuffers(LOG_LEVEL.Error);
    this.postBuffers(LOG_LEVEL.Critical);
  }

  private postBuffers(logLevel: LOG_LEVEL): void {
    const loggerEndpoint: string = this.getLoggerEndpoint(logLevel);
    const logBuffer: LogObj[] = Logger.getLogBufferReference(logLevel);
    if (logBuffer.length > 0) {
      $.ajax({
        headers: {
          'Content-Type': 'application/json'
        },
        url: loggerEndpoint,
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: JSON.stringify(logBuffer)
      });
      logBuffer.splice(0, logBuffer.length);
    }
  }

  private getLoggerEndpoint(logLevel: LOG_LEVEL): string {
    switch (logLevel) {
      case LOG_LEVEL.Loop:
        return `${Logger.ApiServiceUrl}/api/Logger/LogLoop/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Trace:
        return `${Logger.ApiServiceUrl}/api/Logger/LogTrace/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Debug:
        return `${Logger.ApiServiceUrl}/api/Logger/LogDebug/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Information:
        return `${Logger.ApiServiceUrl}/api/Logger/LogInformation/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Warning:
        return `${Logger.ApiServiceUrl}/api/Logger/LogWarning/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Error:
        return `${Logger.ApiServiceUrl}/api/Logger/LogError/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Critical:
        return `${Logger.ApiServiceUrl}/api/Logger/LogCritical/${this._logSource}/${this._logLevel}`;
      default:
        return `${Logger.ApiServiceUrl}/api/Logger/LogTrace/${this._logSource}/${this._logLevel}`;
    }
  }

  /**
   * This method will sync the logLevel with account level log level of the logged in user.
   * User has to be logged in before calling this method.
   */
  syncLogLevel(): void {
    if (Logger.ApiServiceUrl) {
      $.ajax({
        headers: {
          'Content-Type': 'application/json'
        },
        url: `${Logger.ApiServiceUrl}/api/me/LogLevel`,
        xhrFields: {
          withCredentials: true
        },
        type: 'GET',
        success: (logLevel: LOG_LEVEL) => {
          if (logLevel !== undefined && logLevel != null) {
            this._logLevel = logLevel;
          }
        }
      });
    }
  }
}
