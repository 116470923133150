/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework, ofLogger } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * This is to get the app name as it is listed in davinci.
 * CRM apps using a bridge might need this to know which iframe to post messages to.
 */
export function getAppName(): Promise<string> {
  return sendRequestToFramework(OPERATIONS.GET_APP_NAME).then(
    (resolved) => resolved
  );
}
