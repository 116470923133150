/**
 * @packageDocumentation
 * @module DaVinci_API
 */

/**
 * Possible sources of logs
 * Used by [[Logger]]
 */
export enum LOG_SOURCE {
  Other = 'Other',
  OpenFramework = 'OpenFramework',
  DemoApp = 'DemoApp',
  DynamicsApp = 'DynamicsApp',
  SalesforceApp = 'SalesforceApp',
  ZendeskApp = 'ZendeskApp',
  ServiceNowApp = 'ServiceNowApp',
  SapApp = 'SapApp',
  TwilioApp = 'TwilioApp',
  AmazonConnect = 'AmazonConnect',
  SkypeForBusinessApp = 'SkypeForBusinessApp',
  RingCentralApp = 'RingCentralApp',
  GlobalControlApp = 'GlobalControlApp',
  GlobalPresenceApp = 'GlobalPresenceApp',
  AuthService = 'AuthService',
  ApiService = 'ApiService',
  AdminTool = 'AdminTool'
}
