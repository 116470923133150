/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import {
  sendRequestToFramework,
  sendRegisterRequestToFramework
} from '../HelperFunctions';
import { RecordItem } from '../models/RecordItem';
import { IInteraction } from '../models/Interactions';

/**
 * Apps that want to communicate updated interaction details back to the interaction provider apps can use this API.
 *
 * Apps that register using the registerUpdateInteractionToProvider will receive the data.
 *
 * - See [[registerUpdateInteractionToProvider]]
 *
 * @param interaction This is the interaction which should contain the interaction ID, scenario ID and
 *
 * the data to be updated to provider in the details of the interaction.
 */
export function updateInteractionToProvider(
  interaction: IInteraction
): Promise<void> {
  return sendRequestToFramework(OPERATIONS.UPDATE_INTERACTION_TO_PROVIDER, [
    interaction
  ]).then();
}

/**
 * This registers the apps to handle the interaction updates events
 *
 * which are triggered each time updateInteractionToProvider is called.
 *
 * - See [[updateInteractionToProvider]]
 *
 * @param callback This will be triggered whenever updateInteractionToProvider is called.
 */
export function registerUpdateInteractionToProvider(
  callback: (interaction: IInteraction) => Promise<void>
): Promise<void> {
  return sendRegisterRequestToFramework(
    fixInteractionDetails(callback),
    OPERATIONS.UPDATE_INTERACTION_TO_PROVIDER
  );
}

// This fixes the details in the interaction to actually be a RecordItem
function fixInteractionDetails(
  f: (interaction: IInteraction) => Promise<void>
) {
  return (interaction: IInteraction) => {
    if (interaction.details !== undefined && interaction.details !== null) {
      interaction.details = RecordItem.fromJSON(interaction.details);
    }
    return f(interaction);
  };
}
