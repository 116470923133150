/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

export function dialPadNumberClicked(number: any): Promise<void> {
  return sendRequestToFramework(OPERATIONS.DIALPAD_NUMBER_CLICKED, [
    number
  ]).then((val) => {}); // make sure we return void
}

export function speedDialNumberClicked(number: any): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SPEED_DIAL_NUMBER_CLICKED, [
    number
  ]).then((val) => {}); // make sure we return void
}
