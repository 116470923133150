/* eslint-disable max-len */
/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import {
  sendRegisterRequestToFramework,
  sendRequestToFramework
} from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { SearchRecords } from '../models/SearchRecords';
import { CHANNEL_TYPES } from '../models/ChannelTypes';
import { CONTEXTUAL_OPERATION_TYPE } from '../models/ContextualOperation';

/**
 *  Use this to be informed if click-to-dial becomes enabled or disabled
 *
 * - See [[enableClickToDial]]
 * - See [[registerClickToDial]]
 * - See [[clickToDial]]
 * @param callback Called when click to dial is enabled or disabled.
 * If clickToDialEnabled is false then the application should not allow users to preform click-to-dial requests.
 */
export function registerEnableClickToDial(
  callback: (clickToDialEnabled: boolean) => Promise<void>
) {
  return sendRegisterRequestToFramework(
    callback,
    OPERATIONS.ENABLE_CLICK_TO_DIAL
  );
}

/**
 * Use this to enable or disable click-to-dial
 *
 * - See [[registerEnableClickToDial]]
 * - See [[registerClickToDial]]
 * - See [[clickToDial]]
 * @param enabled This set whether or not agents should be allowed to preform click-to-dial
 */
export function enableClickToDial(enabled: boolean): Promise<void> {
  return sendRequestToFramework(OPERATIONS.ENABLE_CLICK_TO_DIAL, [
    enabled
  ]).then((val) => {}); // make sure we return void
}
/**
 * Call this when an agent preforms a click-to-dial. It will tell any Channel Apps to call this number.
 *
 * Example: if a phone number is displayed in the CRM then, when it is clicked by the agent, this event should be called to initiate a call.
 *
 * - See [[registerClickToDial]]
 * - See [[registerEnableClickToDial]]
 * - See [[enableClickToDial]]
 * @param phoneNumber Number to be dialed
 * @param records Records/entities associated to the number(e.g. lead, contact, etc..)
 * @param action Type of transfer of which the data type must be CONTEXTUAL_OPERATION_TYPE and the acceptable values are: BlindTransfer, WarmTransfer or Conference
 * @param interactionID Interaction identification number
 */
export function clickToDial(
  phoneNumber: string,
  records?: SearchRecords,
  channelType?: CHANNEL_TYPES,
  action?: CONTEXTUAL_OPERATION_TYPE,
  interactionID?: string
): Promise<void> {
  return sendRequestToFramework(OPERATIONS.CLICK_TO_DIAL, [
    phoneNumber,
    records,
    channelType,
    action,
    interactionID
  ]).then((val) => {}); // make sure we return void
}
/**
 * Apps that handle interactions should use this to start an interaction when a phone number is clicked in the crm.
 *
 * - See [[clickToDial]]
 * - See [[registerEnableClickToDial]]
 * - See [[enableClickToDial]]
 * @param callback Called whenever an user preforms a click-to-dial
 */
export function registerClickToDial(
  callback: (
    phoneNumber: string,
    records?: SearchRecords,
    channelType?: CHANNEL_TYPES,
    action?: CONTEXTUAL_OPERATION_TYPE,
    intercationID?: string
  ) => Promise<void>
): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.CLICK_TO_DIAL);
}

/**
 * Call this when an agent preforms a click-to-act. It will tell any Channel Apps to call this number.
 *
 * Example: if a phone number is displayed in the CRM then, when it is clicked by the agent, this event should be called to initiate a call.
 *
 * - See [[registerClickToAct]]
 * @param phoneNumber Number to be acted
 * @param records Records/entities associated to the number(e.g. lead, contact, etc..)
 * @param action Type of transfer of which the data type must be CONTEXTUAL_OPERATION_TYPE and the acceptable values are: BlindTransfer, WarmTransfer or Conference
 * @param interactionID Interaction identification number
 */
export function clickToAct(
  phoneNumber: string,
  records?: SearchRecords,
  channelType?: CHANNEL_TYPES,
  action?: CONTEXTUAL_OPERATION_TYPE,
  interactionID?: string,
  data?: any
): Promise<void> {
  return sendRequestToFramework(OPERATIONS.CLICK_TO_ACT, [
    phoneNumber,
    records,
    channelType,
    action,
    interactionID,
    data ? data : {}
  ]).then((val) => {}); // make sure we return void
}
/**
 * Apps that handle interactions should use this to start an interaction when a phone number is clicked in the crm.
 *
 * - See [[clickToAct]]
 * @param callback Called whenever an user preforms a click-to-act
 */
export function registerClickToAct(
  callback: (
    phoneNumber: string,
    records?: SearchRecords,
    channelType?: CHANNEL_TYPES,
    action?: CONTEXTUAL_OPERATION_TYPE,
    interactionID?: string,
    data?: any
  ) => Promise<void>
): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.CLICK_TO_ACT);
}
