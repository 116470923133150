import { Injectable } from '@angular/core';
import { Logger, LOG_SOURCE } from '@amc-technology/davinci-api';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class LoggerService {
  public logger: Logger;
  constructor(private configService: ConfigurationService) {}

  intialize() {
    this.logger = new Logger(
      'SalesforceBYOT',
      false,
      this.configService.config.loggerApiUrl
    );
  }
}
