/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import {
  IRequest,
  IResponse,
  sendRegisterRequestToFramework
} from '../HelperFunctions';
import { INTERCEPTOR_TIMEOUT_ACTION } from '../models/Intercept';

/**
 * This can be used to intercept any DaVinci operation. Intercepted operations will have the operation's payload passed to your callback.
 * The callback can then either:
 *  - resolve
 *    - returning a modified payload.
 *    - This modified payload will then passed to the regular operation handles instead of the original payload.
 *  - reject
 *    - if a IRequest then this will stop the operation from propagating to the normal operation handlers
 *    - if a IResponse then this will send a rejection to the originating app
 *
 * Example
 * ```typescript
 *  registerOperationInterceptor([
 *    OPERATIONS.CLICK_TO_DIAL,
 *    OPERATIONS.CONTEXTUAL_OPERATION,
 *    OPERATIONS.CONTEXTUAL_EVENT],
 *    async (payload: {
 *      pluginName: string;
 *      message: IRequest | IResponse;
 *  }) => {
 *    console.info('INTERCEPTED!! ', payload);
 *    let operation;
 *    if (isResponse(payload.message)) {
 *      operation = payload.message.request.operation;
 *    } else {
 *      operation = payload.message.operation;
 *    }
 *    console.info('operation: ', OPERATIONS[operation]);
 *
 *    if (operation === OPERATIONS.CLICK_TO_DIAL) {
 *      if (!isResponse(payload.message)) {
 *        // force click to dial number to be 555-555-5555
 *        payload.message.data[0] = "555-555-5555";
 *      }
 *    } else if (operation === OPERATIONS.CONTEXTUAL_EVENT) {
 *      // this is an outbound typed in from the dial pad
 *      if (!isResponse(payload.message)) {
 *        // cancel the outbound
 *        throw new Error('A reasonable reason to cancel');
 *      }
 *    } else if (operation === OPERATIONS.CONTEXTUAL_OPERATION) {
 *      // this is intercepting transfer and the like
 *      if (isResponse(payload.message)) {
 *        // force transfer number to be 555-555-5555
 *        payload.message.resolve.displayName = "555-555-5555";
 *        payload.message.resolve.uniqueId = "555-555-5555";
 *      }
 *    }
 *
 *    return payload;
 *  });
 *```
 * @param operationToIntercept List of the operations to intercept. Note: you can't intercept OPERATIONS.INTERCEPT
 * @param callback This function takes the original operation and then returns it (with modifications as desired)
 *  Note that another event will be sent with 'didTimeout' set if a timeout occurred because the interceptor did not reply fast enough
 * @param timeoutInMilliseconds How long the interceptor has to respond to a request before it times out
 * @param timeoutAction What should happen to the original request if a timeout occurs
 */
export function registerOperationInterceptor(
  operationsToIntercept: OPERATIONS[],
  callback: (payload: {
    pluginName: string;
    message: IRequest | IResponse;
    didTimeout?: boolean;
  }) => Promise<{ pluginName: string; message: IRequest | IResponse }>,
  timeoutInMilliseconds?: number,
  timeoutAction?: INTERCEPTOR_TIMEOUT_ACTION
): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.INTERCEPT, [
    operationsToIntercept,
    timeoutInMilliseconds,
    timeoutAction
  ]);
}
