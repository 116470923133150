/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import {
  sendRegisterRequestToFramework,
  sendRequestToFramework
} from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { ISupportedChannel } from '../models/SupportedChannel';

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * One app has to be in charge of managing how one app's presence will effect the other apps.
 * That app should use this to receive events when other app's change their presence.
 *
 * See:
 * - [[registerGetPresence]]
 * - [[onPresenceChanged]]
 * - [[logout]]
 * - [[setPresence]]
 * - [[getPresence]]
 * - [[registerOnPresenceChanged]]
 * - [[registerOnLogout]]
 *
 * @param callback This is called when ever an app request a change in the user's presence.
 * If the given presence/reason is not valid this function should reject.
 * If it is valid then this function should inform the other apps using onPresenceChanged.
 */
export function setSupportedChannels(
  channels: ISupportedChannel[]
): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SUPPORTED_CHANNEL, [channels]);
}

/**
 * Register to receive events where apps inform you what channels they support
 * @param callback Called when another app calls [[setSupportedChannels]]
 */
export function registerSetSupportedChannels(
  callback: (
    originatingAppName: string,
    channels: ISupportedChannel[]
  ) => Promise<void>
) {
  return sendRegisterRequestToFramework(callback, OPERATIONS.SUPPORTED_CHANNEL);
}
