/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import {
  sendRequestToFramework,
  sendRegisterRequestToFramework
} from '../HelperFunctions';
import { RecordItem } from '../models/RecordItem';
import { SearchRecords } from '../models/SearchRecords';
import { IInteraction } from '../models/Interactions';

/**
 * Apps with interactions(phone calls, chats, etc..) should call this every time an interactions is added, removed, or modified.
 *
 * This is important as it allows for screenpops, activities to be saved in CRM, and statistics.
 *
 * Screenpops should not be performed if they are disabled: [[registerScreenpopControlChanged]]
 *
 * - See [[registerOnInteraction]]
 * @param interaction
 */
export function setInteraction(
  interaction: IInteraction
): Promise<SearchRecords> {
  return sendRequestToFramework(OPERATIONS.INTERACTION, [interaction]).then(
    (val: any) => SearchRecords.fromJSON(val)
  );
}

/**
 * This registers the app the handle interaction events, which is triggered each time an interaction changes.
 *
 * - See [[setInteraction]]
 *
 * @param callback This will be called each time an interaction is added or has its state changed.
 * Apps that are integrated with a CRM should preform a screenpop when a new interaction occurs.
 */
export function registerOnInteraction(
  callback: (interaction: IInteraction) => Promise<SearchRecords>
): Promise<void> {
  return sendRegisterRequestToFramework(
    fixInteractionDetails(callback),
    OPERATIONS.INTERACTION
  );
}

// This fixes the details in the interaction to actually be a RecordItem
function fixInteractionDetails(
  f: (interaction: IInteraction) => Promise<SearchRecords>
) {
  return (interaction: IInteraction) => {
    if (interaction.details !== undefined && interaction.details !== null) {
      interaction.details = RecordItem.fromJSON(interaction.details);
    }
    return f(interaction);
  };
}
