import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { byotComponent } from './byot.component';
import { HomeBYOTComponent } from './home/home-byot.component';
import { LoggerService } from './logger.service';
import { ConfigurationService } from './configuration.service';

import { StorageService } from './storage.service';
@NgModule({
  declarations: [byotComponent, HomeBYOTComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeBYOTComponent, pathMatch: 'full' }
    ])
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (configService: ConfigurationService, loggerService: LoggerService) =>
        async () => {
          await configService.loadConfigurationData();
          loggerService.intialize();
        },
      deps: [ConfigurationService, LoggerService],
      multi: true
    },
    LoggerService,
    StorageService
  ],
  bootstrap: [byotComponent]
})
export class AppModule {}
