import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DevLoggingService {
  disabled = true;

  constructor() { }

  // TODO: DELETE ME AND REMOVE ALL REFERENCES BEFORE RELEASE!
  // Chrome debugger is too unreliable, logging required for analysis

  debugLog(logs: string[], color='white', label='', collapsed=true) {

    if (this.disabled) {
      return;
    }

    try {
      collapsed ? console.groupCollapsed('%c' + label, `color: ${color}`) : console.group('%c' + label, `color: ${color}`);
      for (const log of logs ? logs : []) {
        console.log(`%c${log}`, `color: ${color}`);
      }
    console.groupEnd();
    } catch (err) {
      console.groupEnd();
      alert(JSON.stringify(err));
    }
  }
}
