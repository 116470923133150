/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import {
  sendRegisterRequestToFramework,
  sendRequestToFramework
} from '../HelperFunctions';
import { SearchLayouts } from '../models/SearchLayouts';

/**
 * Apps that integrate with CRM should register for this event to tell other apps which fields are important in a record.
 * The [[SearchLayouts]] should be configured either in the CRM or in the app's config in Creator Studio.
 *
 * See [[getSearchLayout]]
 *
 * @param callback This function should return(via a promise) the configured SearchLayouts
 */
export function registerGetSearchLayout(
  callback: () => Promise<SearchLayouts>
) {
  return sendRegisterRequestToFramework(callback, OPERATIONS.GET_SEARCH_LAYOUT);
}

/**
 * Gets the configured search layouts
 *
 * See [[registerGetSearchLayout]]
 * @returns Configured search layouts
 */
export function getSearchLayout(): Promise<SearchLayouts> {
  return sendRequestToFramework(OPERATIONS.GET_SEARCH_LAYOUT).then((val: any) =>
    SearchLayouts.fromJSON(val)
  );
}
