import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Application } from '@amc-technology/applicationangularframework';
import {
  clickToDial,
  clickToAct,
  IInteraction,
  INTERACTION_STATES,
  SearchRecords,
  registerOnLogout,
  IActivity,
  SearchLayouts,
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  registerOnPresenceChanged,
  INTERACTION_MULTIPARTY_STATES,
  registerSetSupportedChannels,
  ISupportedChannel,
  getPresence,
  IGetPresenceResult,
  logout
} from '@amc-technology/davinci-api';
import { bind } from 'bind-decorator';
import { DevLoggingService } from './../dev-logging.service';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';
import { Call } from '../Model/PhoneCall';
import { PROMISE_EVENT_TYPE } from '../Model/PromiseEventTypes';
import { LOG_LEVEL } from '../Model/LogLevel';
import { DeferredPromise } from '../Model/DeferredPromise';
import { IPromiseToResolve } from '../Model/IPromiseToResolve';

@Component({
  selector: 'app-home',
  templateUrl: './home-byot.component.html'
})
export class HomeBYOTComponent extends Application implements OnInit {
  private phoneNumberFormat: Map<string, string> = new Map([]);
  private clickToDialPhoneReformatMap: Map<string, string> = new Map([]);
  private configs: object = {};
  private interactionsList: Map<string, IInteraction> = new Map([]);
  private expiredInteractionsList: Map<string, IInteraction> = new Map([]);
  private presencesFromSalesforce: Map<string, string> = new Map([]);
  private channelToSalesforceMap: Map<string, string> = new Map([]);
  private salesforceToChannelMap: Map<string, string> = new Map([]);
  private promisesToResolve: IPromiseToResolve[] = [];
  private presencesToResolve: IPromiseToResolve[] = [];
  private currentPresence: string;
  private currentReason: string;
  private presenceTimeout: number = 5000;
  private eventTimeout: number = 5000;
  private ctiApiKey: string;
  private RSAPrivateKey: string;
  private transcriptionServiceUrl: string;
  private orgId: string;
  private orgUrl: string;
  private callCenterApiName: string;
  private ctiId: string;
  private ctiExtenstion: string;
  private crmId: string;
  private accountId: string;
  private voiceCallIdToInteractionMap: Map<string, string> = new Map([]);
  private loggedIn: boolean = false;
  private createCall: boolean = false;
  private resolvedAPromise: boolean = false;
  private loggedOut: boolean = false;
  private cloudLogs: boolean = true;
  private consoleLogs: boolean = true;
  private failedHangupAttempts: number = 0;
  agentStatus: any;

  constructor(
    private loggerService: LoggerService,
    private storageService: StorageService,
    private devLogger: DevLoggingService,
    private http: HttpClient
  ) {
    super(loggerService.logger);
    this.storageService.syncWithLocalStorage();
  }

  async ngOnInit() {
    const functionName = 'ngOnInit';
    try {
      await this.loadConfig();
      this.bridgeScripts = this.bridgeScripts.concat([this.getBridgeURL()]);
      await super.ngOnInit();

      this.getSalesforceConfig();
      this.checkAgentStatus();

      this.bridgeEventsService.subscribe('logDebug', this.loggingFromBridgeDebug);
      this.bridgeEventsService.subscribe('logInformation', this.loggingFromBridgeInformation);
      this.bridgeEventsService.subscribe('logTrace', this.loggingFromBridgeTrace);
      this.bridgeEventsService.subscribe('logError', this.loggingFromBridgeError);
      this.bridgeEventsService.subscribe('accept', this.acceptCallEvent);
      this.bridgeEventsService.subscribe('reject', this.rejectCallEvent);
      this.bridgeEventsService.subscribe('hangup', this.hangupCallEvent);
      this.bridgeEventsService.subscribe('mute', this.muteCallEvent);
      this.bridgeEventsService.subscribe('unmute', this.unmuteCallEvent);
      this.bridgeEventsService.subscribe('hold', this.holdCallEvent);
      this.bridgeEventsService.subscribe('unhold', this.unholdCallEvent);
      this.bridgeEventsService.subscribe('addParticipant', this.addParticipant);
      this.bridgeEventsService.subscribe('warmTransfer', this.warmTransfer);
      this.bridgeEventsService.subscribe('blindTransfer', this.blindTransfer);
      this.bridgeEventsService.subscribe('conference', this.conference);
      this.bridgeEventsService.subscribe('removeParticipant', this.removeParticipant);
      // this.bridgeEventsService.subscribe('wrapup', this.wrapupCompleted);

      this.bridgeEventsService.subscribe('devLog', (obj) => this.devLogger.debugLog(obj.logs, obj.color, obj.label, obj.collapsed));
      this.bridgeEventsService.subscribe('presenceChange', this.onPresenceChange);
      this.bridgeEventsService.subscribe('outbound', this.sendOutbound);
      this.bridgeEventsService.subscribe('sendDigits', this.sendDigits);
      this.bridgeEventsService.subscribe('logout', this.logoutUser);

      // registerClickToAct(this.clickToActCallback);
      registerOnPresenceChanged(this.setPresenceCallback);
      registerOnLogout(this.logoutCallback);
      registerSetSupportedChannels(this.getChannelConfig);

      await this.setup();

      this.loggerService.logger.logDebug(
        'BYOT - Home - Component: ngOnInit complete'
      );
    } catch (error) {
      this.log(functionName, [error], 'error');
    }
  }

  log(functionName: string, payload?: any[], logLevel?: any) {
    try {
      if(this.consoleLogs) {
        console.log(`%c${functionName}`, 'background: #222; color: #fe9001; padding: 2px; border-radius:2px');
        if (this.promisesToResolve) {
          console.log(this.promisesToResolve);
        }
        if (payload) {
          for (let i = 0; i < payload.length; i++) {
            if (payload[i]) {
              console.log(payload[i]);
            }
          }
        }
      }

      if (this.cloudLogs) {
        if (payload) {
          if (logLevel) {
            if (logLevel === LOG_LEVEL.trace) {
              this.logger.logTrace('BYOT - Home : TRACE : ' + functionName + ' : ' + JSON.stringify(payload));
            } else if (logLevel === LOG_LEVEL.debug) {
              this.logger.logDebug('BYOT - Home : DEBUG : ' + functionName + ' : ' + JSON.stringify(payload));
            } else if (logLevel === LOG_LEVEL.error) {
              this.logger.logError('BYOT - Home : ERROR : ' + functionName + ' : ' + JSON.stringify(payload));
            } else {
              this.logger.logInformation('BYOT - Home : INFORMATION : ' + functionName + ' : ' + JSON.stringify(payload));
            }
          } else {
            this.logger.logDebug('BYOT - Home : DEBUG : ' + functionName + ' : ' + JSON.stringify(payload));
          }
        } else {
          this.logger.logInformation('BYOT - Home : INFORMATION : START - ' + functionName)
        }

        if (this.promisesToResolve) {
          this.logger.logDebug('BYOT - Home : DEBUG : Promises To Resolve : ' + functionName + ' : ' + JSON.stringify(this.promisesToResolve));
        }
      }
    } catch (error) {
      console.log('Unable to log event');
    }
  }

  /**
   * Functions to handle events from DaVinci API
   */
  public async setup(): Promise<void> {
    const functionName = 'Setup';
    if (this.appConfig['CallControls'] && this.appConfig['CallControls']['variables']) {
      this.configs['CallControls'] = this.appConfig['CallControls']['variables'];
      if (this.appConfig['CallControls']['variables']['eventTimeout']) {
        this.eventTimeout = this.appConfig['CallControls']['variables']['eventTimeout'];
      }
    }
    if (this.appConfig.variables['PhoneNumberFormat']) {
      this.phoneNumberFormat = this.appConfig.variables['PhoneNumberFormat'];
    }
    if (this.appConfig.variables['ClickToDialPhoneReformatMap']) {
      this.clickToDialPhoneReformatMap = this.appConfig.variables['ClickToDialPhoneReformatMap'];
    }
    if (this.appConfig['PresenceMapping'] && this.appConfig['PresenceMapping']['variables']) {
      if (this.appConfig['PresenceMapping']['variables']['channelToSalesforce']) {
        this.channelToSalesforceMap = this.appConfig['PresenceMapping']['variables']['channelToSalesforce'];
      }
      if (this.appConfig['PresenceMapping']['variables']['salesforceToChannel']) {
        this.salesforceToChannelMap = this.appConfig['PresenceMapping']['variables']['salesforceToChannel'];
      }
      if (this.appConfig['PresenceMapping']['variables']['presenceChangeTimeout']) {
        this.presenceTimeout = this.appConfig['PresenceMapping']['variables']['presenceChangeTimeout'];
      }
    }
    if (this.appConfig['Transcription'] && this.appConfig['Transcription']['variables']) {
      if (this.appConfig['Transcription']['variables']['ctiApiKey']) {
        this.ctiApiKey = this.appConfig['Transcription']['variables']['ctiApiKey'];
      }
      if (this.appConfig['Transcription']['variables']['RSAPrivateKey']) {
        this.RSAPrivateKey = this.appConfig['Transcription']['variables']['RSAPrivateKey'];
      }
      if (this.appConfig['Transcription']['variables']['TranascriptionServiceURL']) {
        this.transcriptionServiceUrl = this.appConfig['Transcription']['variables']['TranascriptionServiceURL'];
      }
      if (this.appConfig['Transcription']['variables']['accountId']) {
        this.accountId = this.appConfig['Transcription']['variables']['accountId'];
      }
      if (this.appConfig['Transcription']['variables']['createCall']) {
        this.createCall = this.appConfig['Transcription']['variables']['createCall'];
      }
    }
    this.sendLoginToTranscription();
    await this.bridgeEventsService.sendEvent('configs', this.configs);
    this.log(functionName, [' : END of function : '], 'information');
  }

  async getSalesforceConfig() {
    const functionName = 'getSalesforceConfig';

    // This function pulls the user information from Salesforce for the presence maps and logic app
    try {
      const salesforceConfigs = await this.bridgeEventsService.sendEvent('getSalesforceConfig');
      this.log(functionName, [salesforceConfigs], "debug")
      const presenceMap = salesforceConfigs['presences'];
      const presenceKeys = Object.keys(presenceMap);
      for (let i = 0; i < presenceKeys.length; i ++) {
        const currentPresence = presenceMap[presenceKeys[i]];
        this.presencesFromSalesforce[currentPresence['statusName']] = currentPresence['statusId'];
        this.log(functionName, [' : Presences From Salesforce Current Presence Status Name : ' , this.presencesFromSalesforce[currentPresence['statusName']]], 'debug');
      }
      this.callCenterApiName = salesforceConfigs['callCenterApiName'];
      this.orgUrl = salesforceConfigs['orgUrl'];
      this.orgId = salesforceConfigs['orgId'];
      this.crmId = salesforceConfigs['crmId'];
    } catch (error) {
      this.log(functionName, [error], "error");
    }
  }

  @bind
  async getChannelConfig(originalAppName: string, channels: ISupportedChannel[]) {
    const functionName= 'getChannelConfig';

    try {
      // This callback will set the values for the ctiId and ctiExtension needed for the logic app
      this.ctiId = channels[0].customValues['ctiId'];
      this.ctiExtenstion = channels[0].customValues['ctiExtension'];
      this.log(functionName, ['Set Values for CTI Id and CTI Extension To : ', this.ctiId, this.ctiExtenstion], 'information');
    } catch (error) {
      this.log(functionName, [error], "error");
    }
  }

  private async sendLoginToTranscription() {
    const functionName = 'sendLoginToTranscription';

    try {
      this.log(functionName, ['Attempting to send login event'], 'debug');
      const interval = setInterval(() => {
        this.log(functionName, [`crmId: ${this.crmId} | ctiId: ${this.ctiId}`], 'debug');
        if (this.crmId == null) {
          this.log(functionName, ['CRM ID is NULL : '], 'debug');
          this.getSalesforceConfig();
        }
        if (this.ctiId != null && this.crmId != null) {
          this.log(functionName, ['Sending login event'], 'debug');
          // When an agent logs in send an event with their information to the logic app
          this.http
            .post<string>(
              this.transcriptionServiceUrl,
              {
                crmId: this.crmId,
                ctiId: this.ctiId,
                ctiExtension: this.ctiExtenstion,
                orgUrl: this.orgUrl,
                callCenterApiName: this.callCenterApiName,
                orgId: this.orgId,
                privateKey: this.RSAPrivateKey,
              },
              {
                headers: {
                  type: "loginEvent",
                  accountId: this.accountId,
                },
              }
            )
            .subscribe((response) => {
              this.log(
                functionName,
                ["Login Request Response", response],
                "debug"
              );
            });
          clearInterval(interval);
        }
      }, 5000); // Todo: Make this configurable
    } catch (error) {
      this.log(functionName, ['failed to send login event', error], 'error');
    }
  }

  protected async onInteraction(
    interaction: IInteraction
  ): Promise<SearchRecords> {
    const functionName = 'onInteraction';

    try {
      let interactionStateChange = true;
      this.resolvedAPromise = false;
      this.log(functionName, [`InteractionId: ${interaction.interactionId}\nScenarioId: ${interaction.scenarioId}\nState: ${INTERACTION_STATES[interaction.state]}`, interaction], 'debug');

      await this.checkForActivePromises(interaction);
      // this.loggerService.logger.logDebug(`onInteraction - Sending interaction to bridge ${JSON.stringify(interaction)}`);

      if (interaction && interaction.interactionId && !this.interactionsList.has(interaction.interactionId)) {
        // this.log(functionName, [' : Set Interaction in Interaction List : ', `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'information');
        this.interactionsList.set(interaction.interactionId, interaction);
      } else if (interaction.state === this.interactionsList.get(interaction.interactionId).state) {
        interactionStateChange = false;
      }
      this.interactionsList.set(interaction.interactionId, interaction);
      // this.log(functionName, [`Promise Resolved: ${this.resolvedAPromise}`]);
      if (!this.resolvedAPromise && interactionStateChange !== false) {
        // if a promise has not been resolved we raise an event
        if (interaction.state === INTERACTION_STATES.Alerting) {
          this.sendInbound(interaction);
          this.log(functionName, [' : Interaction State Alerting, Sending Inbound Event : ', `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'information');
        } else if (interaction.state === INTERACTION_STATES.Connected) {
          this.sendConnected(interaction);
          this.log(functionName, [' : Interaction State Connected, Sending Connected Event : ', `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'information');
        } else if (interaction.state === INTERACTION_STATES.Disconnected) {
          this.sendDisconnected(interaction);
          this.log(functionName, [' : Interaction State Disconnected, Sending Disconnected Event : ', `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'information');
        } else if (interaction.state === INTERACTION_STATES.OnHold) {
          this.sendOnHold(interaction);
          this.log(functionName, [' : Interaction State On Hold, Sending On Hold Event : ', `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'information');
        } else if (interaction.state === INTERACTION_STATES.Initiated) {
          this.sendInitiated(interaction);
          this.log(functionName, [' : Interaction State Initiated, Sending Initiated Event : ', `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'information');
        }
      }
    } catch (error) {
      this.log(functionName, [error], "error");
      return Promise.reject(error);
    }
  }

  protected async checkForActivePromises(interaction: IInteraction) {
    const functName = 'checkForActivePromises';
    this.log(functName);
    try {
          if (this.promisesToResolve.length > 0) {
            this.log(functName, [' : Active Promises to Resolve Present : '], 'information');
            for (let i = 0; i < this.promisesToResolve.length; i++) {
              let currentPromise = this.promisesToResolve[i];
              if (Date.now() - currentPromise.deferredPromise.timeCreated > this.eventTimeout) {
                this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.all, functName, 'cleaning up expired promises');
              }

              if (interaction.state === INTERACTION_STATES.Connected) { // Connected Events
                if (interaction.scenarioId === currentPromise.scenarioId) {
                  if (interaction.multiPartyState === INTERACTION_MULTIPARTY_STATES.Conferenced) {
                    await this.resolveDeferredPromise(interaction, i);
                  }
                  if (interaction.interactionId === currentPromise.interactionId) {
                     if (currentPromise.eventData['interactions'][currentPromise.eventData['interactions'].length - 1].details.fields.Phone.Value === interaction.details.fields.Phone.Value) {
                      if (currentPromise.eventType === PROMISE_EVENT_TYPE.accepted) {
                        await this.resolveDeferredPromise(interaction, i);
                      } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.connected) {
                        await this.resolveDeferredPromise(interaction, i);
                      } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.unheld) {
                        await this.resolveDeferredPromise(interaction, i);
                      } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.unmuted) {
                        await this.resolveDeferredPromise(interaction, i);
                      } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.muted) {
                        await this.resolveDeferredPromise(interaction, i);
                      } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.held) {
                        await this.resolveDeferredPromise(interaction, i);
                      }
                    }
                    if (currentPromise.eventType === PROMISE_EVENT_TYPE.removeParticipant) {
                      await this.resolveDeferredPromise(interaction, i);
                    }
                  } else {
                    // if (currentPromise.eventData === interaction.details.fields.Phone.Value) {
                      // Todo: implement phonenumber formatting here
                      if (currentPromise.eventType === PROMISE_EVENT_TYPE.addParticipant) {
                        await this.resolveDeferredPromise(interaction, i);
                      }
                    // }
                  }
                } else {
                  if (currentPromise.eventType === PROMISE_EVENT_TYPE.outbound) {
                    await this.resolveDeferredPromise(interaction, i);
                  }
                }
              } else if (interaction.state === INTERACTION_STATES.Disconnected) { // Disconnected Events
                if (interaction.scenarioId === currentPromise.scenarioId) {
                  if (interaction.interactionId === currentPromise.interactionId) {
                    this.interactionsList.delete(interaction.interactionId);
                    if (currentPromise.eventType === PROMISE_EVENT_TYPE.hangup) {
                      await this.resolveDeferredPromise(interaction, i);
                    } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.warmTransfered) {
                      await this.resolveDeferredPromise(interaction, i);
                    } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.blindTransfered) {
                      await this.resolveDeferredPromise(interaction, i);
                    }
                  }
                }
              } else if (interaction.state === INTERACTION_STATES.Initiated) { // Initiated Events
                if (interaction.scenarioId === currentPromise.scenarioId) {
                  if (interaction.interactionId === currentPromise.interactionId) {
                  } else {
                    if (currentPromise.eventData === interaction.details.fields.Phone.Value) {
                      if (currentPromise.eventType === PROMISE_EVENT_TYPE.addParticipant) {
                        await this.resolveDeferredPromise(interaction, i);
                      }
                    }
                  }
                } else if (currentPromise.scenarioId === '' && currentPromise.interactionId === '') {
                  if (currentPromise.eventType === PROMISE_EVENT_TYPE.outbound) {
                    await this.resolveDeferredPromise(interaction, i);
                  }
                }
              } else if (interaction.state === INTERACTION_STATES.OnHold) { // OnHold Events
                if (interaction.scenarioId === currentPromise.scenarioId) {
                  if (interaction.interactionId === currentPromise.interactionId) {
                    if (currentPromise.eventData['interactions'][currentPromise.eventData['interactions'].length - 1].details.fields.Phone.Value === interaction.details.fields.Phone.Value) {
                      await this.resolveDeferredPromise(interaction, i);
                    }
                  }
                }
              } else if (interaction.state === INTERACTION_STATES.Alerting) { // Alerting Events
              }

            }
          }
    } catch (error) {
      this.log(functName, ['failed to find promise', error], 'error');
    }
  }

  async resolveDeferredPromise(interaction: IInteraction, i: number) {
    const functionName = 'resolveDeferred Promise';
    try {
      // This function removes the promise from the list and resolves it
      // this.log(functionName, ['Resolving Promise for interaction', interaction], 'debug');
      const promiseToResolve: DeferredPromise = this.promisesToResolve[i].deferredPromise;
      promiseToResolve.resolve(interaction);
      const removedPromise = this.promisesToResolve.splice(i, 1);
      this.log(functionName, ['Resolved Promise', removedPromise, , `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'debug');
      this.resolvedAPromise = true;
    } catch (error) {
      this.log(functionName, ['failed to resolve promise', error], 'error');
    }
  }

  @bind
  protected async setPresenceCallback(
    presence: string,
    reason?: string,
    initiatingApp?: string
  ): Promise<any> {
    const functionName = 'setPresenceCallback';

    try {
      this.log(functionName, [presence, reason, initiatingApp], 'debug');
      if (this.loggedIn === false && presence !== 'Pending') {
        this.log(functionName, ['Send Login Bridge Event'], 'information');
        this.bridgeEventsService.sendEvent('login');
        this.loggedIn = true;
      }
      this.currentPresence = presence;
      this.currentReason = reason;
      this.log(functionName, [`Presence: ${presence} | Reason: ${reason} | initiatingApp: ${initiatingApp}`], 'debug');
      if (presence === 'On an interaction') {
        this.log(functionName, ['Ignoring setting of On an interaction']);
        return;
      }
      let presenceSet = false;
      if (this.presencesToResolve.length > 0) {
        for (let i = 0; i < this.presencesToResolve.length; i++) {
          this.log(functionName, ['Promise to be resolved of type Presence'], 'debug');
          const presenceToResolve: DeferredPromise = this.presencesToResolve[i].deferredPromise;
          await presenceToResolve.resolve(presence);
          this.presencesToResolve.splice(i, 1);
          presenceSet = true;
        }
      } else if (!presenceSet) {
        // This event raises a presence change event that was not triggered by Salesforce
        let presenceId = this.presencesFromSalesforce[this.channelToSalesforceMap[presence]];
        this.log(functionName, ['Presnece Change Event, Not triggered by Salesforce', `Presence ID: ${presenceId}`], 'information');
        if (reason !== null && reason !== '') {
          this.log(functionName, ['Reason', reason], 'debug');
          presenceId = this.presencesFromSalesforce[this.channelToSalesforceMap[`${presence}|${reason}`]];
        }
        this.bridgeEventsService.sendEvent('presence', presenceId);
      }
    }  catch (error) {
      this.log(functionName, ['unable to set presence', error], 'error');
      return Promise.reject(false);
    }

  }

  @bind
  private async logoutCallback(reason?: string) {
    const functionName = 'logoutCallBack';

    this.log(functionName, ['Recieved logout Event'], 'information');
    let promiseResolved = false;
    if (this.promisesToResolve.length > 0) {
      this.log(functionName, ['One or More promises to be resolved'], 'information');
      for (let i = 0; i < this.promisesToResolve.length; i++) {
        this.log(functionName, ['Resolving Promise ' + i], 'information');
        if (this.promisesToResolve[i].eventType === PROMISE_EVENT_TYPE.logout) {
          promiseResolved = true;
          this.log(functionName, ['Promise to be resolved is of type Logout. Resolving Logout Promise'], 'information');
          const promiseToResolve: DeferredPromise = this.promisesToResolve[i].deferredPromise;
          this.log(functionName, ['Resolving Logout Promise'], 'information');
          await promiseToResolve.resolve(reason);
          this.promisesToResolve.splice(i, 1);
          break;
        }
      }
    }
    if (!promiseResolved) {
      this.bridgeEventsService.sendEvent('logout');
    }
    this.removeLocalStorageOnLogout();
  }

  // @bind
  // protected async clickToActCallback(phoneNumber: string, records?: SearchRecords, channelType?: CHANNEL_TYPES, action?: CONTEXTUAL_OPERATION_TYPE, interactionId?: string) {
  //   console.log(`Recieved Click To act event\n${phoneNumber}\n$${channelType}\n${action}\n${interactionId}`); // Todo: Remove this for the final version
  // }

  /**
   * Event functions that send events to the bridge for BYOT to handle
   */
  protected async sendInbound(interaction: IInteraction) {
    const functionName = 'sendInbound';
    // When an inbound call is recieved send an event to the logic app to create the voice call objeft in Salesforce
    if (this.createCall) {
      this.log(functionName, ['Creating Voice Call', `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'debug');
      let response = await this.http.post<string>(this.transcriptionServiceUrl, {
        'call_id': interaction.details.fields['callId']['Value'],
        'crmId': this.crmId,
        'ctiId': this.ctiId,
        'ctiExtension': this.ctiExtenstion,
        'callerNumber': interaction.details.fields['Phone']['Value'],
        'callCenterApiName': this.callCenterApiName,
        'orgUrl': this.orgUrl
      },
       {
        'headers': {
          'type': 'callCreationEvent',
          'accountId': this.accountId
        }
      }).toPromise();
      // setTimeout is needed because salesforce initializes the call too soon. So if you accept it, transcription does not work.
      setTimeout(() =>
      {
        this.log(functionName, [Date.now(), 'Call Creation Response', response, , `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'debug');
        this.voiceCallIdToInteractionMap[interaction.interactionId] = response['voiceCallId'];
        this.bridgeEventsService.sendEvent('inbound', { 'interaction': interaction, 'voiceCallId': this.voiceCallIdToInteractionMap[interaction.interactionId] });
      }, 3000);
    } else {
      this.bridgeEventsService.sendEvent('inbound', { 'interaction': interaction, 'voiceCallId': interaction.interactionId });
    }
  }

  protected async sendConnected(interaction: IInteraction) {
    const functionName = 'sendConnected';
    await this.bridgeEventsService.sendEvent('connected', { 'interaction': interaction, 'voiceCallId': this.voiceCallIdToInteractionMap[interaction.interactionId] });
  }

  protected async sendDisconnected(interaction: IInteraction) {
    const functionName = 'sendDisconnected';
    if (this.interactionsList.has(interaction.interactionId)) {
      this.log(functionName, ['Interaction Id present in interaction list', , `InteractionId: ${interaction.interactionId}`, `ScenarioId: ${interaction.scenarioId}`], 'debug');
      this.expiredInteractionsList.set(interaction.interactionId, interaction);
      this.interactionsList.delete(interaction.interactionId);
    }
    await this.bridgeEventsService.sendEvent('disconnected', interaction);
    this.failedHangupAttempts = 0;
  }

  protected async sendOnHold(interaction: IInteraction) {
    const functionName = 'sendOnHold';
    await this.bridgeEventsService.sendEvent('onHold', interaction);
  }

  protected async sendInitiated(interaction: IInteraction) {
    const functionName = 'sendInitiated';
    await this.bridgeEventsService.sendEvent('initiated', interaction);
  }

  async checkAgentStatus() {
    const functionName = 'checkAgentStatus';
    try {
      const currentPresence: IGetPresenceResult = await getPresence();
      this.log(functionName, ['Current Presence', currentPresence], 'debug');
      if (currentPresence.presence.toLowerCase() !== 'pending') {
        this.log(functionName, ['Presence not pending, Bridge Login'], 'information');
        this.bridgeEventsService.sendEvent('login');
      }
    } catch (error) {
      this.log(functionName, [error], 'error');
    }
  }



  /**
   *  Callback functions for the bridge that handle setup and receiving events from BYOT
   */
  @bind
  async acceptCallEvent(call: Call): Promise<any> {
    const functionName = 'acceptCallEvent';
    try {
      if (!call) {
        this.log(functionName, ['Accepted Call is undefined'], 'trace');
        return;
      }

      let noInteractions = false;

      // If the call does not have the necessary data, attempt recovery
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(functionName, ['Call has no interactions, Attempting to Recover'], 'trace');
        const storedCalls = await this.bridgeEventsService.sendEvent('getActiveCalls');

        // No hope if stored call has no interactions either
        if (!storedCalls[call.callId] ||
            !storedCalls[call.callId]['interactions'] ||
            storedCalls[call.callId].interactions.length < 1)
        {
          this.log(functionName, ['Could not recall interactions from local storage'], 'trace');
          noInteractions = true;
        } else {
          // Reapply the stored interactions onto the Call object
          this.log(functionName, ['Stored interactions attached to call object'], 'information');
          call['interactions'] = storedCalls[call.callId].interactions;
        }
      }

      // Remove null and undefined values if present
      const validInteractions = noInteractions ? null : call['interactions'].filter(interaction => interaction && interaction['interactionId']);

      if (await this.callAlreadyInState(call, INTERACTION_STATES.Connected, null, true)) {
        // Call already disconnected, return the interaction
        this.log(functionName, ['Call already accepted, returning interaction'], 'information');
        this.devLogger.debugLog(null, null, "JoshP: Call already accepted, returning interaction...");
        const earlyInteraction: any = await this.callAlreadyInState(call, INTERACTION_STATES.Connected);
        return earlyInteraction;
      }

      this.log(functionName, ['Attempting to accept Call', call], 'debug');
      await clickToAct(
        noInteractions ? null : call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Answer,
        noInteractions ? null : call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      this.log(functionName, ['Creating Promise'], 'information');
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.accepted,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(functionName, ['Promises to resolve', this.promisesToResolve], 'information');
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.accepted, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Unable process answer call event Error: ${JSON.stringify(error)} Call: ${call}`);
    }
  }

  @bind
  async rejectCallEvent(call: Call) {
    const functionName = 'rejectCallEvent';
    // Todo: Determine a way to get a response on the success case for a rejection event
    try {
      return await clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Reject,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
    } catch (error) {
      this.logger.logError(
        `Unable process reject call event Error: ${error} Call: ${call}`
      );
    }
  }

  @bind
  async hangupCallEvent(call: Call) {
    const functionName = 'hangupCallEvent';
    try {
      this.log(functionName, ['Hangup Call Event'], 'information');

      let noInteractions = false;

      // If the call does not have the necessary data, attempt recovery
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(functionName, ['Call has no interactions. Attemping to recover'], 'trace');
        this.devLogger.debugLog(null, 'LawnGreen', 'JoshP: hangupCallEvent() will crash because call has no interactions, Attempting to recover.');
        const storedCalls = await this.bridgeEventsService.sendEvent('getActiveCalls');

        // No hope if stored call has no interactions either
        if (!storedCalls[call.callId] ||
            !storedCalls[call.callId]['interactions'] ||
            storedCalls[call.callId].interactions.length < 1)
        {
          this.log(functionName, ['Could not recall interactions from localStorage']);
          noInteractions = true;
        } else {
          // Reapply the stored interactions onto the Call object
          this.log(functionName, ['Reapplying stored interactions onto call object'], 'information');
          call['interactions'] = storedCalls[call.callId].interactions;
        }

      }

      // Remove null and undefined values if present
      const validInteractions = noInteractions ? null : call['interactions'].filter(interaction => interaction && interaction['interactionId']);

      if (await this.callAlreadyInState(call, INTERACTION_STATES.Disconnected, null, true)) {
        // Call already disconnected, return the interaction
        this.log(functionName, ['Call already dropped, returning interaction...'], 'debug');
        this.devLogger.debugLog(null, null, "JoshP: Call already dropped, returning interaction...");
        const earlyInteraction: any = await this.callAlreadyInState(call, INTERACTION_STATES.Disconnected);
        return earlyInteraction;
      }

      this.log(functionName, ["Calling ClickToAct() to HANGUP call...",
          JSON.stringify(
            {
              phone: noInteractions ? null : validInteractions[validInteractions.length - 1].details.fields['Phone']['Value'],
              records: null,
              Channel: 'Telephony',
              Operation: 'Hangup',
              interactionId: noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
              data: call
            },
            null,
            2
          )
        ], 'debug');
      this.log(functionName, ['Hanging up call', call], 'debug');
      clickToAct(
        noInteractions ? null : call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Hangup,
        noInteractions ? null : call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.hangup,
        scenarioId:  noInteractions ? null : call.interactions[call.interactions.length - 1].scenarioId,
        interactionId:  noInteractions ? null : call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.hangup, functionName, call), this.eventTimeout);
      this.failedHangupAttempts = 0;
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      if(this.failedHangupAttempts > 1) {
        if (call.interactions.length > 0 && Object.keys(this.interactionsList).length === 0) {
          // if there are no calls in the interactions list simply end the call as no call is being tracked
          this.log(functionName, ['Removing a call with no active interactions', call]);
          this.failedHangupAttempts = 0;
          return Promise.resolve(call.interactions[call.interactions.length - 1]);
        }
      }
      this.failedHangupAttempts++;
      this.log(functionName, ['Unable process hangup call event', call, error], LOG_LEVEL.error);
    }
  }

  @bind
  async muteCallEvent(call: Call) {
    const functionName = 'muteCallEvent';
    try {
      // TODO test with CTI that supports Mute
      clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Mute,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.muted,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.muted, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Unable process mute call event Error: ${error} Call: ${call}`);
    }
  }

  @bind
  async unmuteCallEvent(call: Call) {
    const functionName = 'unmuteCallEvent';
    try {
      // TODO test with CTI that supports Mute
      clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Unmute,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.unmuted,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.unmuted, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Unable process unmute call event Error: ${error} Call: ${call}`);
    }
  }

  // Compares SalesForce Call object with records in DPG. Returns false if
  // the DaVinci interaction object is not already in the given state, and
  // returns the interaction if it is already in the given state. This is
  // for convenience as the function can be used for true/false control flow
  // and also to retrieve the interaction that must be returned to SalesForce.
  // TODO: Remove 'debug' param
  async callAlreadyInState(call: Call, state: INTERACTION_STATES, recurse=true, debug=false): Promise<boolean | IInteraction> {
    const functionName = 'callAlreadyInState';
    let endLog = true;
    try {
    console.groupCollapsed("JoshP: callAlreadyInState() BEGIN");
    // If the call from SalesForce has the fields we require...
    if (call &&
        call['interactions'] &&
        call.interactions.length > 0) {

      // Call can sometimes have undefined elements in its interactions array, filter them out.
      const validInteractions = call.interactions.filter(interaction => Boolean(interaction));
      this.log(functionName, ['Filtering out undefined elements in interactions array'], 'information');
      // Return false if there are no valid interactions to check with
      if (validInteractions.length < 1) {
        this.log(functionName, ['No valid interactions present'], 'debug');
        return false;
      }
      // If the call were are checking for is DISCONNECTED, check for it as an expired Interaction.
      // If it's not there then we know we haven't received DISCONNECTED yet
      const interactionList = state === INTERACTION_STATES.Disconnected ? this.expiredInteractionsList : this.interactionsList;


      // Special handling for a disconnected event
      // If any of the nested conditions fail, it is okay to continue through
      // this function normally.
      if (state === INTERACTION_STATES.Disconnected) {
        this.log(functionName, ['Interaction is in disconnect state'], 'debug');
        if (!this.expiredInteractionsList || !(this.expiredInteractionsList.get(validInteractions[validInteractions.length - 1].interactionId))) {
          // We don't have an 'already-disconnected' interaction.
          // If we don't have an active interaction either, we know something broke
          this.log(functionName, ['No interaction disconnected and no active interaction.'], 'trace');
          if (!this.interactionsList || !(this.interactionsList.get(validInteractions[validInteractions.length - 1].interactionId))) {
            // Since we have no interaction to return, just return a dummy interaction with state set to DISCONNECTED.
            const retInteraction = validInteractions[validInteractions.length - 1];
            retInteraction.state = INTERACTION_STATES.Disconnected;
            this.log(functionName, ['Lost Call, returning dummy interaction'], 'debug');
            return retInteraction;
          }
        }
      }


      if (!interactionList || interactionList.size < 1) {
        // No interactions to compare against, return false
        this.log(functionName, ['No interaction to compare against'], 'debug');
        return false;
      }
      this.log(functionName, [`Got this Interactions List (${state === INTERACTION_STATES.Disconnected ? 'Expired' : 'Current'})`], 'debug');
      // Return Interaction if already in the given state, or false otherwise.
      const ret = interactionList.get(validInteractions[validInteractions.length - 1].interactionId).state === state ? interactionList.get(validInteractions[validInteractions.length - 1].interactionId) : false;
      this.log(functionName, [ret ? `returning early Interaction:\n${JSON.stringify(ret, null, 2)}` : 'JoshP: No Error handling required.'], 'debug');
      this.devLogger.debugLog(null, null, ret ? `returning early Interaction:\n${JSON.stringify(ret, null, 2)}` : 'JoshP: No Error handling required.', true);
      return ret;
    }

    // Call did not contain what we required, see if it is in localStorage of Bridge
    const activeCalls = await this.bridgeEventsService.sendEvent('getActiveCalls');

    if (call &&
        call['callId'] &&
        activeCalls &&
        activeCalls[call.callId]) {

      // If the call in localStorage had what we needed, try again.
      this.log(functionName, ['Call in local storage contains required informaion, Recursing...'], 'debug');
      endLog = !recurse;
      return recurse ? this.callAlreadyInState(activeCalls[call.callId], state, false) : false;
    }
    this.log(functionName, ['No conditions pass, returning false'], 'debug');
    return false;
  } catch (error) {
    this.log(functionName, [error], 'error');
  } finally {
    endLog ? console.groupEnd() : '';
  }
  }

  @bind
  async holdCallEvent(call: Call) {
    const functionName = 'holdCallEvent';
    try {
      let noInteractions = false;

      // If the call does not have the necessary data, attempt recovery
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(functionName, ['Call contians no interactions, attempting to recover.'], 'debug');
        this.devLogger.debugLog(null, 'LawnGreen', 'JoshP: holdCallEvent() will crash because call has no interactions, Attempting to recover.');
        const storedCalls = await this.bridgeEventsService.sendEvent('getActiveCalls');

        // No hope if stored call has no interactions either
        if (!storedCalls[call.callId] ||
            !storedCalls[call.callId]['interactions'] ||
            storedCalls[call.callId].interactions.length < 1)
        {
          this.log(functionName, ['Could not recall interactions from localStorage'], 'debug');
          this.devLogger.debugLog(null, 'DarkRed', 'JoshP: Could not recall interactions from localStorage', true);
          noInteractions = true;
        } else {
          // Reapply the stored interactions onto the Call object
          this.log(functionName, ['Reapplying stored interactions onto call object'], 'information');
          call['interactions'] = storedCalls[call.callId].interactions;
        }

      }

      // Remove null and undefined values if present
      const validInteractions = noInteractions ? null : call['interactions'].filter(interaction => interaction && interaction['interactionId']);
      if (await this.callAlreadyInState(call, INTERACTION_STATES.OnHold, null, true)) {
        // Call already held, return the interaction
        this.log(functionName, ['Call already held, returning interaction...'], 'debug');
        this.devLogger.debugLog(null, null, "JoshP: Call already held, returning interaction...");
        const earlyInteraction: any = await this.callAlreadyInState(call, INTERACTION_STATES.OnHold);
        return earlyInteraction;
      }

      // this.log(functionName, ["Calling ClickToAct() to HOLD call...",
      //   JSON.stringify({phone: noInteractions ? null : validInteractions[validInteractions.length - 1].details.fields['Phone']['Value'],
      //     records: null,
      //     Channel: 'Telephony',
      //     Operation: 'Hold',
      //     interactionId: noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
      //     data: call}, null, 2)], 'debug');
      clickToAct(
        noInteractions ? null : validInteractions[validInteractions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Hold,
        noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        call
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.held,
        scenarioId: noInteractions ? null : validInteractions[validInteractions.length - 1].scenarioId,
        interactionId: noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.held, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(functionName, ['Failed top hold call', call]);
    }
  }

  @bind
  async unholdCallEvent(call: Call) {
    const functionName = 'unholdCallEvent';
    try {
      let noInteractions = false;

      // Attempt recovery after loss of data
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(functionName, ['Call has no interacitons, attempting to recover.'], 'debug');
        this.devLogger.debugLog(null, 'LawnGreen', 'JoshP: unholdCallEvent() will crash because call has no interactions, Attempting to recover.');
        const storedCalls = await this.bridgeEventsService.sendEvent('getActiveCalls');

        // No hope if stored call has no interactions either
        if (!storedCalls[call.callId] ||
            !storedCalls[call.callId]['interactions'] ||
            storedCalls[call.callId].interactions.length < 1) {
          this.log(functionName, ['Could not recall interactions from localStorage'], 'debug');
          this.devLogger.debugLog(null, 'DarkRed', 'JoshP: Could not recall interactions from localStorage', true);
          noInteractions = true;
        } else {
          call['interactions'] = storedCalls[call.callId].interactions;
          this.log(functionName, ['Attaching call interactions onto call object', storedCalls[call.callId].interactions], 'debug');
        }

      }

      const validInteractions = noInteractions ? null : call['interactions'].filter(interaction => interaction && interaction['interactionId']);

      // if (await this.callAlreadyInState(call, INTERACTION_STATES.Connected)) {
      //   // Call already unheld, return the interaction
      //   this.devLogger.debugLog(null, null, "JoshP: Call already unheld, Cranking the party up to 11");
      //   return this.callAlreadyInState(call, INTERACTION_STATES.Connected);
      // }

      // this.log(functionName, ["Calling ClickToAct() to UNHOLD call...",
      //   JSON.stringify({phone: noInteractions ? null : validInteractions[validInteractions.length - 1].details.fields['Phone']['Value'],
      //     records: null,
      //     Channel: 'Telephony',
      //     Operation: 'Unhold',
      //     interactionId: noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
      //     data: call}, null, 2)], 'debug');

      clickToAct(
        noInteractions ? null : validInteractions[validInteractions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Unhold,
        noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        call
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.unheld,
        scenarioId: noInteractions ? null : validInteractions[validInteractions.length - 1].scenarioId,
        interactionId: noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.unheld, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(
        `Unable process unhold call event Error: ${error} Call: ${call}`
      );
      this.devLogger.debugLog([JSON.stringify(error, null, 2)], 'DarkRed', `JoshP: Failed to process unhold call event`);
    }
  }

  @bind
  async addParticipant(participant: {call: Call, contact: {phoneNumber: string}}) {
    const functionName = 'addParticipant';
    try {
      this.log(functionName, [participant], 'debug');
      // Todo: implement PhoneNumber Formatting here
      await clickToAct(
        participant.contact.phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.AddParticipant,
        participant.call.interactions[participant.call.interactions.length - 1].interactionId,
        participant.call
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.addParticipant,
        scenarioId: participant.call.interactions[participant.call.interactions.length - 1].scenarioId,
        interactionId: '',
        deferredPromise: new DeferredPromise(),
        eventData: participant.contact.phoneNumber
      });

      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.addParticipant, functionName, participant), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.log(functionName, ['Failed to add participant', error], 'error');
      return Promise.reject('Failed to add participant');
    }
  }

  @bind
  async blindTransfer(blindTransfer) {
    const functionName = 'blindTransfer';
    try {
      // TODO: implement PhoneNumber Formatting here
      await clickToAct(
        blindTransfer.contact.phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.BlindTransfer,
        blindTransfer.call.interactions[blindTransfer.call.interactions.length - 1].interactionId,
        null
      );

      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.blindTransfered,
        scenarioId: blindTransfer.call.interactions[blindTransfer.call.interactions.length - 1].scenarioId,
        interactionId: blindTransfer.call.interactions[blindTransfer.call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: blindTransfer.contact.phoneNumber
      });

      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.blindTransfered, functionName, blindTransfer.contact.phoneNumber), this.eventTimeout);

      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Failed to perform blind transfer for call ${JSON.stringify(blindTransfer.call)}\nError: ${JSON.stringify(error)}`);
      return Promise.reject('Failed to perform blind transfer');
    }
  }

  @bind
  async warmTransfer(call: Call) {
    const functionName = 'warmTransfer';
    try {
      // Todo: implement PhoneNumber Formatting here
      await clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.WarmTransfer,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.warmTransfered,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.warmTransfered, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Failed to addParticipant for call ${JSON.stringify(call)}\nError: ${JSON.stringify(error)}`);
      return Promise.reject('Failed to add participant');
    }
  }

  @bind
  async conference(calls: Call[]) {
    const functionName = 'conference';
    try {
      // Todo: implement PhoneNumber Formatting here
      const call = calls[1];
      clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Conference,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );

      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.conference,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.conference, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Failed to conference for calls ${JSON.stringify(calls)}\nError: ${JSON.stringify(error)}`);
      return Promise.reject('Failed to add participant');
    }
  }

  @bind
  async removeParticipant(call: Call) {
    const functionName = 'removeParticipant';
    try {
      const partiesObject = JSON.parse(call.interactions[call.interactions.length - 1].details.fields['conferenceParties']['Value'].replace(/\\"/g, '"'));
      const conferenceKeys = Object.keys(partiesObject);
      let participantIndex = '0';
      for (let i = 0; i < conferenceKeys.length; i++) {
        this.log(functionName, ['Iterating through conference keys', `ConferenceKey: ${conferenceKeys[i]}`], 'trace');
        if (partiesObject[conferenceKeys[i]] === call.phoneNumber) {
          this.log(functionName, ['Located the conference key that matches the call phone number'], 'information');
          participantIndex = i.toString();
          break;
        }
      }
      // Todo: implement PhoneNumber Formatting here
      await clickToAct(
        call.phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.RemoveParticipant,
        call.interactions[call.interactions.length - 1].interactionId,
        participantIndex
      );
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.removeParticipant,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.removeParticipant, functionName, call), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Failed to addParticipant for call ${JSON.stringify(call)}\nError: ${JSON.stringify(error)}`);
      return Promise.reject('Failed to add participant');
    }
  }

  // Currently the intended method to handle wrapup is to forward it from the channel to salesforce.
  // The salesforce wrapup UI should be disabled for this process.
  // @bind
  // async wrapupCompleted(call) {
  //   try {
  //     await clickToAct(
  //       '',
  //       null,
  //       CHANNEL_TYPES.Telephony,
  //       CONTEXTUAL_OPERATION_TYPE.SetPresence,
  //       '',
  //       {presence: 'Ready', reason: 'Ready'}
  //     );
  //   } catch (error) {
  //     this.logger.logError(`Failed to complete wrapup for call ${JSON.stringify(call)}\nError: ${JSON.stringify(error)}`);
  //   }
  // }

  @bind
  async onPresenceChange(agentPresence: {agentStatus: string, agentStatusInfo: {statusId: string, statusApiName: string, statusName: string }}) {
    const functionName = 'onPresenceChange';
    try {
      if (this.loggedOut === true) {
        // Do not change the presence after a logout is triggered
        this.log(functionName, ['User already logged out, no need to change presence']);
        return;
      }
      this.log(functionName, ['Setting presence from Salesforce', agentPresence], 'debug');
      let presenceMapped = this.salesforceToChannelMap[agentPresence.agentStatusInfo.statusName];
      let presence = '';
      let reason = '';
      let pending = '';
      this.log(functionName, [presenceMapped], 'debug');
      if (presenceMapped.includes('|')) {
        // This will split a presence if it maps to a reason code using the | character
        const presenceReason = presenceMapped.split('|');
        presence = presenceReason[0];
        reason = presenceReason[1];
        this.log(functionName, ['Presence maps to a reason code', `ReasonL ${reason}`], 'information');
        if (presenceReason.length > 2) {
          pending = presenceReason[2];
          this.log(functionName, ['Presence contains: presence, reason, and pending', `Presence: ${presence} | Reason: ${reason} | Pending: ${pending}`], 'debug');
        }
      } else {
        this.log(functionName, ['No reason code, just presence'], 'information');
        presence = presenceMapped;
      }

      if (presence === 'Ready' && reason === '') {
        this.log(functionName, ['Presence is Ready. No Reason'] , 'infomation');
        // reason = 'Ready';
      } else if (presence === 'NotReady' && reason === '') {
        this.log(functionName, ['Presence is Not Ready. No Reason'] , 'infomation');
        reason = 'Break';
      }
      this.log(functionName, [`Presence: ${presence} | Reason: ${reason} | Pending: ${pending}`] , 'debug');
      if (this.currentPresence === presence && this.currentReason === reason) {
        this.log(functionName, ['Agent already in presence and reason', presence, reason]);
        return Promise.resolve(presence);
      }
      await clickToAct(
        '',
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.SetPresence,
        '',
        {presence: presence, reason: reason, pending: pending}
      );
      this.presencesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.presence,
        scenarioId: '',
        interactionId: '',
        deferredPromise: new DeferredPromise(),
        eventData: {presence: presence, reason: reason, pending: pending}
      });
      setTimeout( () => {
        if (this.presencesToResolve.length > 0) {
          for (let i = 0; i < this.presencesToResolve.length; i++) {
            if (this.presencesToResolve[i].eventType === PROMISE_EVENT_TYPE.presence) {
              const presenceToReject: DeferredPromise = this.presencesToResolve[i].deferredPromise;
              if ((Date.now() - presenceToReject.timeCreated) > this.presenceTimeout){
                this.log(functionName, ['RejectingPresence Timeout', presenceToReject]);
                presenceToReject.reject(presence);
                this.presencesToResolve.splice(i, 1);
              }
            }
          }
        }
      }, this.presenceTimeout);
      return this.presencesToResolve[this.presencesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(
        `Unable to setPresence to softphone.\nError:${JSON.stringify(error)}`
      );
      return Promise.reject(`Failed to set presence`);
    }
  }

  @bind
  async sendOutbound(phoneNumber) {
    const functionName = 'sendOutboud';
    try {
      this.log(functionName, [`Outbound Dial: ${phoneNumber}`]);
      // Todo: Implement PhoneNumber Formatting
      await clickToDial(phoneNumber);

      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.outbound,
        scenarioId: '',
        interactionId: '',
        deferredPromise: new DeferredPromise(),
        eventData: phoneNumber
      });
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.outbound, functionName, phoneNumber), this.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
    } catch (error) {
      this.logger.logError(`Unable to send outbound event. Error: ${JSON.stringify(error)}`);
    }
  }

  @bind
  async sendDigits(digits) {
    const functionName = 'sendDigits';
    // Todo: this needs to be disccussed as to how this will be handled in each CTI that supports this
    try {
      return await clickToAct(digits, null, null, CONTEXTUAL_OPERATION_TYPE.DTMF);
    } catch (error) {
      this.logger.logError(
        `Unable process sendDigits event Error: ${error} Call: ${digits}`
      );
    }
  }

  @bind
  logoutUser() {
    const functionName = 'logoutUser';
    // Todo: When the agent is set to offline in the softphone the agent needs to be signed out of agent
    this.log(functionName, ['Logout Request Recieved'], 'information');
    this.loggedOut = true;
    this.promisesToResolve.push({
      eventType: PROMISE_EVENT_TYPE.logout,
      scenarioId: '',
      interactionId: '',
      deferredPromise: new DeferredPromise(),
      eventData: null
    });
    logout();
    return this.promisesToResolve[this.promisesToResolve.length -1].deferredPromise.promise;
  }

  /**
   * Loggers for the bridge
   */
  @bind
  loggingFromBridgeDebug(loggingEvent) {
    try {
      this.logger.logDebug(`Debug event from Bridge : ${JSON.stringify(loggingEvent)}`);
    } catch (error) {
      this.logger.logError(`Unable to log event from Bridge: ${JSON.stringify(loggingEvent)}\nError: ${JSON.stringify(error)}`);
    }
  }

  @bind
  loggingFromBridgeInformation(loggingEvent) {
    try {
      this.logger.logInformation(`Information event from Bridge : ${JSON.stringify(loggingEvent)}`);
    } catch (error) {
      this.logger.logError(`Unable to log event from Bridge: ${JSON.stringify(loggingEvent)}\nError: ${JSON.stringify(error)}`);
    }
  }

  @bind
  loggingFromBridgeTrace(loggingEvent) {
    try {
      this.logger.logTrace(`Event from Bridge : ${JSON.stringify(loggingEvent)}`);
    } catch (error) {
      this.logger.logError(`Unable to log event from Bridge: ${JSON.stringify(loggingEvent)}\nError: ${JSON.stringify(error)}`);
    }
  }

  @bind
  loggingFromBridgeError(loggingEvent) {
    try {
      this.logger.logError(`Event from Bridge : ${JSON.stringify(loggingEvent)}`);
    } catch (error) {
      this.logger.logError(`Unable to log event from Bridge: ${JSON.stringify(loggingEvent)}\nError: ${JSON.stringify(error)}`);
    }
  }

  /**
   * Logic and cleanup functions for the component
   */
  protected removeLocalStorageOnLogout(reason?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.clear();
        resolve('Success');
      } catch (error) {
        reject(error);
      }
    });
  }

  protected formatPhoneNumber(
    inputNumber: string,
    phoneNumberFormat: Object
  ): string {
    const functionName = 'FormatPhoneNumber';
    try {
      this.logger.logDebug(
        'BYOT - Home : START : Formatting Phone Number. Input Number : ' +
          inputNumber +
          '. Configured Format : ' +
          JSON.stringify(phoneNumberFormat)
      );
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        this.log(functionName, ['A single iteration through configured input formats for phone number'], 'information');
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          this.log(functionName, ['Input Format and Input Number are of same length'], 'information');
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (
            (inputFormat.match(/x/g) || []).length !==
            (outputFormat.match(/x/g) || []).length
          ) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (
              inputFormat[j] !== '?' &&
              inputNumber[j] !== inputFormat[j]
            ) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber =
                  outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.logger.logDebug(
              'BYOT - Home : END : Formatting Phone Number. Input Number : ' +
                inputNumber +
                '. Configured Format : ' +
                JSON.stringify(phoneNumberFormat) +
                '. Output Number : ' +
                outputNumber
            );
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.logger.logError(
        'BYOT - Home : ERROR : Formatting Phone Number. Input Number : ' +
          inputNumber +
          '. Configured Format : ' +
          JSON.stringify(phoneNumberFormat) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.logger.logTrace(
      'BYOT - Home : END : Formatting Phone Number. Input Number : ' +
        inputNumber +
        '. Configured Format : ' +
        JSON.stringify(phoneNumberFormat) +
        '. Output Number : ' +
        inputNumber
    );
    return inputNumber;
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(
      this.clickToDialPhoneReformatMap
    );
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat =
          this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (
          (inputFormat.match(/x/g) || []).length !==
          (outputFormat.match(/x/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/\(/g) || []).length !==
          (number.match(/\(/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/-/g) || []).length !==
          (number.match(/-/g) || []).length
        ) {
          continue;
        }

        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber =
                outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  private rejectPromiseTimeout(promiseType: PROMISE_EVENT_TYPE, originalFunctionName: string, event: any) {
    const functionName = 'rejectPromiseTimeout';
    try {
      if (this.promisesToResolve.length > 0) {
        for (let i = 0; i < this.promisesToResolve.length; i++) {
          const promiseToReject: DeferredPromise = this.promisesToResolve[i].deferredPromise;
          if ((Date.now() - promiseToReject.timeCreated) > this.eventTimeout){
            if (this.promisesToResolve[i].eventType === promiseType || promiseType === PROMISE_EVENT_TYPE.all) {
              this.log(functionName, [originalFunctionName, `Rejecting Promise`, promiseToReject]);
              promiseToReject.reject(event);
              this.promisesToResolve.splice(i, 1);
            }
          }
        }
      }
    } catch (error) {
      this.log(functionName, ['Failed to reject promise', error]);
    }
  }

  /**
   * Default DaVinci functions used to control softphone settings for a CRM
   * Not currently implemented
   */
  protected isToolbarVisible(): Promise<boolean> {
    return this.bridgeEventsService.sendEvent('isToolbarVisible');
  }

  protected saveActivity(activity: IActivity): Promise<string> {
    throw new Error('Method not implemented.');
  }

  protected getSearchLayout(): Promise<SearchLayouts> {
    throw new Error('Method not implemented.');
  }

  protected formatCrmResults(crmResults: any): SearchRecords {
    throw new Error('Method not implemented.');
  }
}
