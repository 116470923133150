/**
 * @packageDocumentation
 * @module DaVinci_API
 */
let sequenceID: number = null;
/**
 * Gets a sequential id. This can be used for scenarioIds and interactionIds in [[IInteraction]]s.
 */
export function getSequenceID(): string {
  if (sequenceID === null) {
    sequenceID =
      parseInt(
        window.localStorage.getItem('ContactCanvasCloudCommons.getSequenceID'),
        10
      ) || 0;
  }
  sequenceID++;
  window.localStorage.setItem(
    'ContactCanvasCloudCommons.getSequenceID',
    sequenceID.toString()
  );
  return sequenceID.toString();
}
