/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * This function sets the user attributes for current user.
 *
 * @param userId: The user ID of the logged in user.
 * @param attributes: The attributes which need to be changed, strigified.
 */
export function setUserAttributes(
  userId: string,
  attributes: string
): Promise<boolean> {
  return sendRequestToFramework(OPERATIONS.SET_USER_ATTRIBUTES, [
    userId,
    attributes
  ]);
}
