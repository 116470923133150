/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRegisterRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

export function registerForFrameworkNotifications(
  callback: (newChannel: any) => Promise<any>
): Promise<void> {
  return sendRegisterRequestToFramework(
    callback,
    OPERATIONS.FRAMEWORK_NOTIFICATIONS
  );
}
